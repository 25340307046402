import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderToolBarComponent } from '../../organisms/header-tool-bar/header-tool-bar.component';
import { SidebarComponent } from '../../organisms/sidebar/sidebar.component';
import { UserMenu } from '@domain/models/auth/user-menu';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'git-layout',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    HeaderToolBarComponent,
    SidebarComponent
  ],
  template: `
    <git-header-tool-bar
      (optionsToggled)="onOptionsToggled($event)"
    ></git-header-tool-bar>
    <div class="flex">
      <git-sidebar
        [userMenu]="userMenu"
        [isActive]="sidebarActive"
      ></git-sidebar>
      <section class="content w-full">
        <router-outlet></router-outlet>
      </section>
    </div>
  `,
  styles: []
})
export class LayoutComponent {
  protected userMenu: UserMenu;
  protected sidebarActive = true;

  onOptionsToggled(state: boolean) {
    this.sidebarActive = state;
  }

  constructor() {
    this.userMenu = {
      name: 'my Menu',
      items: [
        {
          cdresource: 1,
          contextPath: '/apps',
          name: 'Aplicaciones',
          image: 'sitemap',
          order: 1,
          target: '',
          windowOptions: '',
          children: [],
          active: false
        },
        {
          cdresource: 2,
          contextPath: '/roadmap',
          name: 'Roadmap',
          image: 'map',
          order: 2,
          target: '',
          windowOptions: '',
          children: [],
          active: false
        },
        {
          cdresource: 3,
          contextPath: '/reports',
          name: 'Reportes',
          image: 'chart-bar',
          order: 3,
          target: '',
          windowOptions: '',
          children: [
            {
              name: 'Roadmap',
              contextPath: '/roadmap',
              cdresource: 4,
              order: 1,
              active: true,
              children: [],
              image: 'sliders-h',
              target: '',
              windowOptions: ''
            }
          ],
          active: false
        }
      ]
    };
  }
}
