import { Component, EventEmitter, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconDirective } from '@view/atoms/icons';
import { InlineTitleDirective } from '@view/atoms/fields';

@Component({
  selector: 'git-header-site-title',
  standalone: true,
  imports: [CommonModule, InlineTitleDirective, IconDirective],
  template: `
    <div class="flex justify-content-between">
      <span gitInlineTitle color="secondary" class="mr-2 font-bold"
        >Gestión integral de TI</span
      >
      <i
        gitIcon
        icon="align-justify"
        color="secondary"
        [clickable]="true"
        (click)="onToggled()"
        (keypress)="onToggled()"
        tabindex="0"
      ></i>
    </div>
  `,
  styles: []
})
export class HeaderSiteTitleComponent {
  @Output() toggled = new EventEmitter<boolean>();

  private isActive = true;

  onToggled() {
    this.isActive = !this.isActive;
    this.toggled.emit(this.isActive);
  }
}
