import { Routes } from '@angular/router';

import { LayoutComponent } from './templates/layout/layout.component';
// gen_imports

/**
 * objeto que almacena la ruta base sobre la cual se asigna el modulo manejador
 */
export const APP_ROUTES: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: 'apps',
        loadChildren: () =>
          import('./pages/apps/apps.routes').then((route) => route.appRoutes)
      },
      {
        path: 'roadmap',
        loadChildren: () =>
          import('./pages/iniciatives/initiatives.routes').then(
            (route) => route.initiativesRoutes
          )
      },
      {
        path: 'reports',
        loadChildren: () =>
          import('./pages/reports/reports.routes').then(
            (route) => route.reportsRoutes
          )
      }
    ]
  },
  {
    path: '**',
    redirectTo: 'apps'
  }
];
