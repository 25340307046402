import { Component, HostBinding } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InlineTextDirective } from '@view/atoms/fields';

@Component({
  selector: 'git-header-user-settings',
  standalone: true,
  imports: [CommonModule, InlineTextDirective],
  template: `
    <ul
      class="list-none pl-0 pr-0 transition-all transition-duration-500 opacity-0"
      [ngClass]="{ 'opacity-100': isActive }"
    >
      <li
        class="cursor-pointer p-2 pl-4 pr-4 gt_font_color"
        [ngClass]="{ gt_bg_color_secondary: mouseOvered }"
        (click)="userMenuActive = false"
        (keypress)="userMenuActive = false"
        (mouseover)="mouseOvered = true"
        (focus)="mouseOvered = true"
        (mouseout)="mouseOvered = false"
        (blur)="mouseOvered = false"
        tabindex="0"
      >
        <i class="pi pi-power-off mr-2"></i>
        <span gitInlineText>Cerrar Sesion</span>
      </li>
    </ul>
  `,
  styles: []
})
export class HeaderUserSettingsComponent {
  protected userMenuActive = true;
  protected mouseOvered = false;
  protected isActive = false;

  private baseClasses = [
    'absolute',
    'w-20rem',
    'gt_header_user_settings_position'
  ];

  @HostBinding('class') hostClasses = this.baseClasses;

  toogleVisibility() {
    this.isActive = !this.isActive;
    this.hostClasses = this.isActive
      ? [...this.baseClasses, 'gt_bg_surface']
      : this.baseClasses;
  }
}
