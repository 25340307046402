import {
  Directive,
  HostBinding,
  Input,
  OnChanges,
  SimpleChanges
} from '@angular/core';

@Directive({
  selector: '[gitRoundedImg]',
  standalone: true
})
export class RoundedImgDirective implements OnChanges {
  @Input({ required: true }) id = '';

  @HostBinding('class') elementClass = 'gt_rounded_avatar';
  @HostBinding('src') elementSrc = `assets/images/${this.id}`;

  ngOnChanges(changes: SimpleChanges) {
    this.elementSrc = `assets/images/${changes.id.currentValue}`;
  }
}
