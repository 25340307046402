import { Component, ContentChild, EventEmitter, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderUserSettingsComponent } from '../header-user-settings/header-user-settings.component';
import { ExpanderIconDirective } from '@view/atoms/icons';
import { RoundedImgDirective } from '@view/atoms/imgs';
import { InlineTitleDirective } from '@view/atoms/fields';

@Component({
  selector: 'git-header-user-block',
  standalone: true,
  imports: [
    CommonModule,
    ExpanderIconDirective,
    RoundedImgDirective,
    InlineTitleDirective
  ],
  template: `
    <div class="flex w-20rem">
      <div class="flex flex-column mt-1 mr-2">
        <span gitInlineTitle color="secondary">Pedrvevi</span>
        <span gitInlineTitle color="secondary"
          >Último ingreso: 27/08/2023 10:00:00</span
        >
      </div>
      <div
        class="flex align-items-center cursor-pointer"
        (click)="onSettingsToggle()"
        (keypress)="onSettingsToggle()"
        tabindex="0"
      >
        <img gitRoundedImg id="user.jpg" alt="user logo" />
        <i gitExpanderIcon [open]="open" style="color: white;"></i>
      </div>
      <ng-content></ng-content>
    </div>
  `
})
export class HeaderUserBlockComponent {
  protected open = false;

  @Output() settingsToggle = new EventEmitter<boolean>();

  @ContentChild(HeaderUserSettingsComponent) userSettingsComponent: any;

  onSettingsToggle() {
    this.open = !this.open;
    this.userSettingsComponent.toogleVisibility();
  }
}
