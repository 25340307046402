import { Component, EventEmitter, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderSiteTitleComponent } from '../../molecules/header-site-title/header-site-title.component';
import { HeaderUserBlockComponent } from '../../molecules/header-user-block/header-user-block.component';
import { HeaderUserSettingsComponent } from '../../molecules/header-user-settings/header-user-settings.component';

@Component({
  selector: 'git-header-tool-bar',
  standalone: true,
  imports: [
    CommonModule,
    HeaderSiteTitleComponent,
    HeaderUserBlockComponent,
    HeaderUserSettingsComponent
  ],
  template: `
    <div
      class="flex justify-content-between align-items-center p-1 gt_bg_color_primary"
    >
      <git-header-site-title
        (toggled)="onHambugerToggled($event)"
      ></git-header-site-title>
      <git-header-user-block>
        <git-header-user-settings></git-header-user-settings>
      </git-header-user-block>
    </div>
  `
})
export class HeaderToolBarComponent {
  @Output() optionsToggled = new EventEmitter<boolean>();

  onHambugerToggled(state: boolean) {
    this.optionsToggled.emit(state);
  }
}
