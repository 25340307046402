import {
  Directive,
  HostBinding,
  Input,
  OnChanges,
  SimpleChanges
} from '@angular/core';

@Directive({
  selector: '[gitInlineTitle]',
  standalone: true
})
export class InlineTitleDirective implements OnChanges {
  @Input() color: 'primary' | 'secondary' = 'primary';
  @Input() size: 'high' | 'medium' = 'medium';

  @HostBinding('class')
  elementClass = `gt_font_color_${this.color} ${this.getSizeClass(this.size)}`;

  ngOnChanges(changes: SimpleChanges) {
    const currColor = changes.color ? changes.color.currentValue : 'primary';
    const currSize = changes.size ? changes.size.currentValue : 'medium';

    this.elementClass = `gt_font_color_${currColor} ${this.getSizeClass(
      currSize
    )}`;
  }

  getSizeClass(size: string) {
    console.log('InlineTitleDirective', size);
    switch (size) {
      case 'high':
        return 'gt_font_h5';
      case 'medium':
        return 'gt_font_sub1';
      default:
        return 'gt_font_sub2';
    }
  }
}
