import {
  Directive,
  HostBinding,
  Input,
  OnChanges,
  SimpleChanges
} from '@angular/core';

@Directive({
  selector: '[gitInlineText]',
  standalone: true
})
export class InlineTextDirective implements OnChanges {
  @Input() color: 'primary' | 'secondary' = 'primary';

  @HostBinding('class')
  elementClass = `gt_font_color_${this.color} gt_font_caption`;

  ngOnChanges(changes: SimpleChanges) {
    this.elementClass = `gt_font_color_${changes.color.currentValue} gt_font_caption`;
  }
}
