import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { InlineTextDirective } from '@view/atoms/fields';
import { IconDirective } from '@view/atoms/icons';
import { LinkDirective } from '@view/atoms/fields/link.directive';
import { UserMenuItem } from '@domain/models/auth/user-menu';
import { SidebarSubItemComponent } from '../sidebar-sub-item/sidebar-sub-item.component';

@Component({
  selector: 'git-sidebar-item',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    InlineTextDirective,
    LinkDirective,
    IconDirective,
    SidebarSubItemComponent
  ],
  template: `
    <li
      class="mt-2 px-2 cursor-pointer gt_font_color gt_sidebar_item gt_font_sub1"
      [ngClass]="{ gt_sidebar_item_active: item.active }"
    >
      <a
        [routerLink]="
          item.children && item.children.length > 0
            ? null
            : '/'.concat(item.contextPath)
        "
        gitLink
        class="gt_sidebar_item_link ml-2"
        (click)="toggleSubmenu(item)"
      >
        <i gitIcon [icon]="item.image" class="mr-1"></i>
        <span>{{ item.name }}</span>
        <em
          *ngIf="item.children && item.children.length > 0"
          class="pi pi-angle-down gt_sidebar_item_arrow"
        ></em>
      </a>
      <git-sidebar-sub-item
        *ngIf="item.children && item.children.length > 0"
        [basePath]="item.contextPath"
        [visible]="item.active"
        [itemsMenu]="item.children"
        [paddingLeft]="20"
      >
      </git-sidebar-sub-item>
    </li>
  `,
  styles: []
})
export class SidebarItemComponent {
  @Input({ required: true }) item!: UserMenuItem;

  /**
   * Cambia el estado activo/inactivo del menuitem pasado como parametro
   *
   * @param menu objeto menuitem seleccionado
   */
  toggleSubmenu(menu: UserMenuItem): void {
    // desactivamos todos los menus
    const isActive = menu.active;
    //this.sidebarService.inactivateAllItems(this.userMenu?.menuItems || []);

    menu.active = !isActive;
  }
}
