import {
  Directive,
  HostBinding,
  Input,
  OnChanges,
  SimpleChanges
} from '@angular/core';

@Directive({
  selector: '[gitIcon]',
  standalone: true
})
export class IconDirective implements OnChanges {
  private baseClass = 'pi';

  @Input({ required: true }) icon!: string;
  @Input() color: 'primary' | 'secondary' = 'primary';
  @Input() clickable = false;

  @HostBinding('class') elementClass = this.baseClass;

  ngOnChanges(changes: SimpleChanges) {
    const icon = changes.icon ? changes.icon.currentValue : this.icon;
    const color = changes.color ? changes.color.currentValue : this.color;
    const clickable = changes.clickable
      ? changes.clickable.currentValue
      : this.clickable;

    this.elementClass = `${this.baseClass} pi-${icon} gt_icon_${color}`;
    this.elementClass += clickable ? ' gt_icon_clickable' : '';
  }
}
