import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidebarItemComponent } from '../../molecules/sidebar-item/sidebar-item.component';
import { UserMenu } from '@domain/models/auth/user-menu';

@Component({
  selector: 'git-sidebar',
  standalone: true,
  imports: [CommonModule, SidebarItemComponent],
  template: `
    <ul
      class="list-none p-1 m-0 transition-transform transition-duration-500 gt_sidebar gt_bg_surface"
      [ngClass]="{
        '-translate-x-100 absolute': !isActive
      }"
    >
      <git-sidebar-item
        *ngFor="let menuItem of userMenu.items"
        [item]="menuItem"
      ></git-sidebar-item>
    </ul>
  `
})
export class SidebarComponent {
  @Input({ required: true }) userMenu!: UserMenu;
  @Input() isActive = true;
}
